<template>
  <dash-page-new
      v-if="$store.state.auth.userType === 'superadmin'"
      title="Фінансовий облік"
      subtitle="WeStudy"
      icon="mdi-briefcase-outline"
      no-side
      :side-navigation="navigationSelect"
      navigation-route="accounting"
  >
    <template #header_action>

      <v-btn v-if="$store.state.settings.pageData.headerAction"
             @click="$store.state.settings.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             dark
      >
        {{ $store.state.settings.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>

  </dash-page-new>
</template>

<script>

export default {
  name: "education",
  data() {
    return {
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text : 'Платежі'     , value : 'payments'  , path :  ''   , icon : 'mdi-cash-multiple' ,    } ,
        { text : 'Видатки'     , value : 'expenses'  , path :  'expenses'   , icon : 'mdi-abacus' ,    } ,
        { text : 'Статистика'  , value : 'stats'     , path :  'stats'   , icon : 'mdi-chart-line' ,    } ,
      ]
    },
  },
  methods : {
  },
}
</script>

<style scoped lang="scss">
</style>
